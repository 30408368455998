/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: '',
  data: function data() {
    return {
      mapcontrol: '',
      flag: '',
      buserInfo: {},
      //车辆信息
      longitude: '',
      //
      latitude: '',
      //
      pois: [],
      //轨迹数组
      timer: ''
    };
  },
  methods: {
    getBusPosition: function getBusPosition() {
      var _this = this;

      var that = this;
      that.$utils.Tools.loadShow();
      this.$api.Bus.selectByDevbh(this.devSerial).then(function (response) {
        //   this.$utils.Tools.loadHide();
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.pois = _this.$utils.Store.getItem('pois') || [];
          _this.buserInfo = data;

          if (_this.longitude == data.gps.split(',')[0] && _this.latitude == data.gps.split(',')[1]) {
            clearInterval(_this.timer);
            _this.timer = setInterval(function () {
              _this.getBusPosition();
            }, 1000 * 10);
            return;
          }

          _this.longitude = data.gps.split(',')[0];
          _this.latitude = data.gps.split(',')[1];
          var obj = {
            longitude: _this.longitude,
            latitude: _this.latitude
          };
          var pois = [];
          var poisList = JSON.parse(_this.$utils.Store.getItem('poisList')) || [];
          poisList.push(obj);

          _this.$utils.Store.setItem('poisList', JSON.stringify(poisList)); // poisList=this.$utils.Store.getItem('poisList');


          poisList.forEach(function (element) {
            pois.push(new window.BMap.Point(element.longitude, element.latitude));
          }); //  if(pois.length==1){
          //     this.getpoist(this.longitude, this.latitude)
          //  }else{
          //       this.busMap(this.longitude, this.latitude, pois)
          // }

          _this.busMap(_this.longitude, _this.latitude, pois);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    // 绘制轨迹的方法
    busMap: function busMap(longitude, latitude, pois) {
      var _this2 = this;

      // this.$utils.Tools.loadShow();
      this.mapcontrol = 21;
      this.flag = 2; // longitude=116.350658
      // latitude=37.938285

      this.$utils.Position.getPath('allmap', longitude, latitude, pois, 15, 10000, function (data) {
        if (data.flag === false) {
          _this2.$utils.Tools.toastShow(_this2.$t('location.failed'));
        }

        _this2.$utils.Tools.loadHide();
      });
    } //   getpoist(longitude, latitude){
    //     // mapcontrol = 10;
    //     // flag = true;
    //     this.$utils.Position.getMarkerBd('allmap', longitude, latitude,14, true,
    //     (data) => {
    //       if (data.flag === false) {
    //         this.$utils.Tools.toastShow(i18n.t('location.failed'));
    //       } else {
    //         this.$utils.Store.setItem('address', data.address);
    //         this.$utils.Store.setItem('latitude', data.latitude);
    //         this.$utils.Store.setItem('longitude', data.longitude);
    //       }
    //       this.$utils.Tools.loadHide();
    //     },
    //   );
    // }

  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
    this.$utils.Store.removeItem('poisList');
    this.$utils.Store.removeItem('addressIndx');
    this.$utils.Tools.loadHide();
  },
  created: function created() {
    var _this3 = this;

    this.$utils.Store.setItem('addressIndx', 1);
    this.devSerial = this.$route.query.devSerial;
    this.getBusPosition();
    this.timer = setInterval(function () {
      _this3.getBusPosition();
    }, 1000 * 3);
  },
  mounted: function mounted() {}
};